const Travel = () => {
  return (
    <svg
      id="Layer_1"
      width={60}
      height={60}
      data-name="Layer 1"
      viewBox="0 0 122.88 107.95"
    >
      <title>travelling</title>
      <path d="M115.81,32.94c1.92-.64,3.94.38,5.28,2a9.31,9.31,0,0,1,1,2.54,7.64,7.64,0,0,1,.19,2.52c-1.08,2.65-3,3.6-5.27,5.15a48.92,48.92,0,0,1-6.88,2.91c-1.27.47-2.55.91-3.85,1.31s-2.6.77-3.9,1.09a15.24,15.24,0,0,1-3.36.37A15.49,15.49,0,0,1,88.89,47a35.57,35.57,0,0,1-3-2.89l-.3-.31L80.41,58.45A51,51,0,0,0,86.61,62c6.7,3.13,12.21,5.71,13.39,16.24.16,1.36.15,8.89.09,14.85h22.79V108H0v-7.22H19.49a4.92,4.92,0,1,1,7.26-1.89,4.83,4.83,0,0,1-1.62,1.89H51.06C52.37,97.05,54.85,89.2,56,85.94c2.52-7.22,4.94-14.64,6.71-20.53a19.25,19.25,0,0,1-2.76-4.36A9.74,9.74,0,0,1,59,57a10,10,0,0,1,.14-1.64l6.34-18.14c-1.34-.07-2.88-.47-4.14.09-2.44,1.06-6,6.26-8.16,8.4l-2.85,2.88c-2.11,2.14-3.55,4.51-6.66,4.44l-1.86,3.87,0,0L26.18,89.51l-.32-.17A8.16,8.16,0,0,0,15,93.16a8.62,8.62,0,0,0-.39,1l-8.25-4L4.48,94,0,91.61l2.11-4.39a5.24,5.24,0,0,1-.29-5L7.89,69.6l6,2.72A1.3,1.3,0,1,0,15,70L9,67.25l3.7-7.69L19,62.4A1.3,1.3,0,1,0,20,60l-6.17-2.8L17,50.68a5.21,5.21,0,0,1,6.94-2.43L38,55l1.74-3.62a4.89,4.89,0,0,1-.57-6.91l6.57-6.65c3.17-3.22,6.17-7.65,10.07-9.85A20.84,20.84,0,0,1,68,25.22c2.07,0,4.26.23,6.32.43l2.41.21c9.48.61,13.89,5.59,17.33,9.47,1.53,1.72,2.84,3.19,4.26,3.87.67.31,1.72-.13,2.92-.63L110,35.31,115.81,33v0ZM88,93.13c.08-5.55.14-11.74.06-12.95s-.86-4.24-2.08-4.8a52.1,52.1,0,0,0-5-1.87c-2.37-.78-4.31-1.57-6.65-2.22-1.72,5.67-4,12.51-6.52,19l-3.59,10.41H76.65v-7.6ZM76.43.61a11.35,11.35,0,1,1-7.67,10.73A11.35,11.35,0,0,1,76.43.61Z" />
    </svg>
  );
};
export default Travel;
