const Map = () => {
  return (
    <svg
      fill="#000000"
      width={50}
      height={50}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M360.439,57.731c-22.739,0-41.239,18.5-41.239,41.239s18.5,41.239,41.239,41.239s41.239-18.5,41.239-41.239
			S383.178,57.731,360.439,57.731z M360.439,109.792c-5.967,0-10.821-4.855-10.821-10.821s4.855-10.821,10.821-10.821
			s10.821,4.855,10.821,10.821C371.26,104.937,366.406,109.792,360.439,109.792z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M436.999,131.423c3.01-10.952,4.55-21.82,4.55-32.453c0-44.724-36.386-81.111-81.111-81.111s-81.111,36.386-81.111,81.111
			c0,10.633,1.54,21.501,4.55,32.453H0v362.717h512V131.423H436.999z M413.098,181.64c0.323-0.477,0.649-0.954,0.967-1.431
			c0.423-0.632,0.842-1.27,1.26-1.911c0.213-0.327,0.427-0.655,0.638-0.982c0.504-0.781,1.004-1.569,1.501-2.363
			c0.064-0.102,0.127-0.204,0.19-0.306c2.552-4.096,5.018-8.363,7.324-12.806h56.603v151.212l-97.046-97.046
			c4.656-4.663,10.055-10.452,15.638-17.197c0.156-0.189,0.311-0.374,0.468-0.565c0.37-0.45,0.741-0.906,1.113-1.366
			c0.32-0.395,0.642-0.795,0.963-1.196c0.306-0.382,0.611-0.763,0.919-1.152c0.6-0.76,1.201-1.528,1.803-2.309
			c0.02-0.025,0.039-0.052,0.058-0.077c0.336-0.436,0.669-0.884,1.005-1.326c0.421-0.555,0.844-1.109,1.256-1.665
			c0.252-0.339,0.503-0.687,0.754-1.03c0.481-0.654,0.961-1.308,1.432-1.963c0.285-0.396,0.567-0.801,0.851-1.201
			c0.421-0.595,0.843-1.19,1.255-1.785C412.401,182.667,412.75,182.153,413.098,181.64z M481.582,356.069v27.963L348.493,250.941
			l13.981-13.981L481.582,356.069z M360.439,48.277c27.952,0,50.693,22.741,50.693,50.693c0,14.642-3.855,28.691-9.603,41.518
			c-1.341,2.996-2.79,5.921-4.313,8.775c-0.16,0.299-0.321,0.597-0.483,0.894c-0.613,1.132-1.24,2.25-1.877,3.356
			c-0.159,0.277-0.316,0.558-0.477,0.833c-0.767,1.315-1.546,2.613-2.339,3.889c-0.169,0.273-0.343,0.537-0.513,0.808
			c-0.611,0.972-1.228,1.934-1.851,2.883c-0.317,0.484-0.638,0.963-0.958,1.441c-0.49,0.731-0.982,1.452-1.478,2.168
			c-0.349,0.504-0.696,1.011-1.046,1.507c-0.559,0.791-1.12,1.565-1.682,2.336c-0.623,0.854-1.246,1.693-1.873,2.523
			c-0.355,0.47-0.71,0.943-1.065,1.406c-0.497,0.647-0.993,1.283-1.489,1.913c-0.288,0.366-0.575,0.727-0.862,1.087
			c-0.534,0.67-1.07,1.339-1.602,1.99c-0.046,0.056-0.091,0.109-0.137,0.165c-1.303,1.591-2.597,3.122-3.872,4.593
			c-0.07,0.08-0.14,0.163-0.21,0.244c-0.618,0.712-1.232,1.406-1.84,2.089c-0.109,0.123-0.218,0.244-0.328,0.366
			c-2.603,2.907-5.097,5.54-7.39,7.866c-0.029,0.029-0.059,0.059-0.087,0.089c-1.161,1.176-2.27,2.275-3.315,3.29
			c-8.915-8.656-22.454-23.318-33.3-41.568c-0.134-0.226-0.272-0.446-0.405-0.674c-0.365-0.622-0.721-1.256-1.079-1.886
			c-0.444-0.782-0.886-1.568-1.319-2.362c-0.259-0.474-0.516-0.949-0.771-1.427c-0.659-1.241-1.308-2.491-1.937-3.76
			c-0.042-0.084-0.085-0.167-0.128-0.252c-0.741-1.505-1.458-3.031-2.149-4.573c-5.752-12.831-9.609-26.885-9.609-41.531
			C309.746,71.018,332.487,48.277,360.439,48.277z M295.9,161.84c2.308,4.443,4.773,8.709,7.324,12.806
			c0.064,0.102,0.127,0.204,0.19,0.306c0.497,0.795,0.997,1.583,1.501,2.363c0.211,0.328,0.424,0.655,0.638,0.982
			c0.419,0.641,0.837,1.28,1.26,1.911c0.318,0.478,0.644,0.954,0.967,1.431c0.349,0.513,0.698,1.028,1.048,1.534
			c0.413,0.596,0.834,1.191,1.255,1.786c0.284,0.401,0.566,0.805,0.851,1.201c0.47,0.655,0.951,1.309,1.432,1.963
			c0.251,0.343,0.503,0.69,0.754,1.03c0.413,0.556,0.835,1.11,1.256,1.665c0.335,0.442,0.669,0.89,1.005,1.326
			c0.019,0.025,0.039,0.052,0.058,0.077c0.601,0.781,1.203,1.549,1.803,2.309c0.306,0.388,0.612,0.769,0.919,1.152
			c0.321,0.401,0.642,0.801,0.963,1.196c0.372,0.458,0.742,0.916,1.113,1.366c0.156,0.191,0.312,0.376,0.468,0.565
			c6.42,7.756,12.599,14.25,17.683,19.223L223.251,333.167L118.413,228.328l66.488-66.488H295.9z M141.886,161.84l-44.98,44.98
			l-44.981-44.98H141.886z M30.417,463.724v-91.047c47.038,6.702,84.344,44.008,91.046,91.047H30.417z M152.119,463.723
			c-7.043-63.832-57.87-114.659-121.701-121.702v-20.386c75.023,7.198,134.891,67.065,142.088,142.088H152.119z M203.042,463.723
			c-7.367-91.808-80.817-165.258-172.625-172.625v-107.75l280.374,280.375H203.042z M481.583,463.723H353.807L244.759,354.675
			l82.226-82.226l154.598,154.598V463.723z"
          />
        </g>
      </g>
    </svg>
  );
};
export default Map;
