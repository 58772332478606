const TariffsIcon = () => {
  return (
    <svg
      fill="black"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={120}
    >
      <style type="text/css">
        {`
          .st0 {
            fill: none;
          }
        `}
      </style>

      <path
        d="M12.1,2c-0.2,0-0.5,0.1-0.7,0.3l-2,1.8L6.9,3.7C6.3,3.6,5.8,4,5.7,4.6L5.3,7.1L3,8.3C2.5,8.6,2.3,9.2,2.5,9.7L3.7,12
	l-1.1,2.3c-0.2,0.5,0,1.1,0.4,1.4l2.3,1.2l0.4,2.5c0.1,0.6,0.6,0.9,1.2,0.9l2.5-0.4l1.8,1.8c0.4,0.4,1,0.4,1.4,0l1.8-1.8l2.5,0.4
	c0.6,0.1,1.1-0.3,1.2-0.9l0.4-2.5l2.3-1.2c0.5-0.3,0.7-0.9,0.4-1.4L20.3,12l1.1-2.3c0.2-0.5,0-1.1-0.4-1.4l-2.3-1.2l-0.4-2.5
	c-0.1-0.6-0.6-0.9-1.2-0.9l-2.5,0.4l-1.8-1.8C12.6,2.1,12.3,2,12.1,2z M9.9,7c0.6,0,1.1,0.2,1.4,0.6C11.7,8,11.8,8.4,11.8,9v0.5
	c0,0.6-0.2,1.1-0.5,1.4c-0.3,0.4-0.8,0.6-1.4,0.6S8.8,11.3,8.5,11C8.2,10.6,8,10.1,8,9.5V9c0-0.6,0.2-1,0.5-1.4S9.3,7,9.9,7z M9.9,8
	C9.7,8,9.5,8.2,9.4,8.3C9.3,8.5,9.2,8.7,9.2,9v0.5c0,0.3,0.1,0.5,0.2,0.7s0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3s0.2-0.4,0.2-0.7V9
	c0-0.3-0.1-0.5-0.2-0.7S10.1,8,9.9,8z M13.6,8l0.9,0.5l-4.1,7.6l-0.9-0.5L13.6,8z M14.1,12.5c0.6,0,1.1,0.2,1.4,0.6S16,14,16,14.5
	V15c0,0.6-0.2,1.1-0.5,1.4S14.7,17,14.1,17s-1.1-0.2-1.4-0.6s-0.5-0.9-0.5-1.4v-0.5c0-0.6,0.2-1,0.5-1.4S13.5,12.5,14.1,12.5z
	 M14.1,13.5c-0.2,0-0.4,0.1-0.5,0.3s-0.2,0.4-0.2,0.7V15c0,0.3,0.1,0.5,0.2,0.7s0.3,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3
	s0.1-0.4,0.1-0.7v-0.5c0-0.3-0.1-0.5-0.2-0.7C14.5,13.6,14.3,13.5,14.1,13.5z"
      />
      <rect className="st0" width="24" height="24" />
    </svg>
  );
};

export default TariffsIcon;
