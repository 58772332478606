const Award = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width={60}
      height={60}
      viewBox="0 0 564.188 564.188"
      // style="enable-background:new 0 0 564.188 564.188;"
    >
      <g>
        <g>
          <path d="M89.888,522.112l74.587-32.513l32.513,74.588l76.5-200.812c-40.163-1.912-78.413-15.3-107.1-38.25L89.888,522.112z" />
          <path d="M397.8,325.125c-30.6,22.95-66.938,36.338-107.1,38.25l76.5,200.812l32.513-74.588l74.587,32.513L397.8,325.125z" />
          <path
            d="M453.263,172.125C453.263,76.5,376.763,0,281.138,0S109.013,76.5,109.013,172.125s76.5,172.125,172.125,172.125
			S453.263,267.75,453.263,172.125z M348.075,267.75l-66.938-43.987L214.2,267.75l28.688-76.5L185.513,153h66.938l28.688-76.5
			l28.688,76.5h66.938l-57.375,38.25L348.075,267.75z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
export default Award;
